import * as THREE from 'three';

class GeometricAnimation {
  constructor() {
    this.container = document.querySelector('.animation-container');
    if (!this.container) {
      console.error('Animation container not found');
      return;
    }

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });

    this.init();
  }

  init() {
    // Setup renderer
    this.renderer.setSize(256, 256);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.container.appendChild(this.renderer.domElement);

    // Create wireframe icosahedron
    const geometry = new THREE.IcosahedronGeometry(1, 1);
    const material = new THREE.MeshPhongMaterial({
      color: 0x6366F1, // matches our accent color
      wireframe: true,
      transparent: true,
      opacity: 0.8
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.scene.add(this.mesh);

    // Add lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(1, 1, 1);
    this.scene.add(directionalLight);

    // Position camera
    this.camera.position.z = 2.5;

    // Start animation loop
    this.animate();
  }

  animate = () => {
    requestAnimationFrame(this.animate);

    // Rotate mesh
    if (this.mesh) {
      this.mesh.rotation.x += 0.003;
      this.mesh.rotation.y += 0.005;
    }

    this.renderer.render(this.scene, this.camera);
  }

  // Handle window resize
  onWindowResize = () => {
    this.renderer.setSize(256, 256);
  }
}

// Initialize animation when DOM is loaded
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => new GeometricAnimation());
} else {
  new GeometricAnimation();
}
